body {
  margin: 0;
}

.leaflet-container {
  width: 100%;
  height: calc(100vh);
}

.leaflet-tooltip-own {
  position: absolute !important;
  margin: 0 !important;
  font-size: 0.55rem;
  padding: 0 !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
  font-weight: bold;
}

.leaflet-tooltip-own-2 {
  color: white !important;
  position: absolute !important;
  margin: 0 !important;
  font-size: 0.55rem;
  padding: 0 !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
  font-weight: bold;
}

.basemap {
  display: inline-block;
  cursor: pointer;
}

.basemaps.closed .basemap {
  display: none;
}

.basemaps.closed .basemap.alt {
  display: inline-block;
}

.basemaps.closed .basemap.alt h4 {
  display: none;
}

.basemap img {
  width: 64px;
  border: 2px solid #fff;
  margin: 0 2px;
  border-radius: 40px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
}

#button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  z-index: 400;
}
#homeButton {
  position: absolute;
  top: 85px;
  left: 1px;
  padding: 10px;
  z-index: 400;
}

.layerControl {
  border-radius: 10px;
  text-align: center;
  position: absolute;
  width: 215px;
  max-width: 100%;
  padding: 25px;
  background-blend-mode: luminosity;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
}

.MuiFormControlLabel-label {
  color: #fff;
}
