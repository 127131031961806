@font-face {
  font-family: "Museo Sans";
  src: url(./assets/fonts/MuseoSans_500/Fonts/MuseoSans_500.otf) format("truetype");
}

@font-face {
  font-family: "Museo Sans 100";
  src: url("./assets/fonts/Museo Sans 100/MuseoSans-100.otf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Museo Sans 300";
  src: url("./assets/fonts/Museo Sans 300/MuseoSans-300.otf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Museo Sans 500";
  src: url("./assets/fonts/Museo Sans 500/MuseoSans_500.otf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Museo Sans 700";
  src: url("./assets/fonts/Museo Sans 700/MuseoSans_700.otf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Museo 300";
  src: url("./assets/fonts/museo-cufonfonts/Museo300-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Museo 700";
  src: url("./assets/fonts/museo-cufonfonts/Museo700-Regular.otf") format("truetype");
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  color: #333;
  background-color: #faf7ef;
  font-family: "Museo Sans 300", Arial, sans-serif;
  font-size: 16px;
  line-height: 170%;
  font-weight: 300;
  overflow-x: hidden;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: block;
}

.img-magnifier-glass {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 500px;
  height: 500px;
  z-index: 999;
}
